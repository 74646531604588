import { useSearchParams } from '@remix-run/react';
import { useNavigate } from '@remix-run/react';
import { format } from 'date-fns';
import capitalize from 'lodash/capitalize';
import { useCallback, useMemo } from 'react';
import Select from 'react-select';

import { type DtoProduct } from '@lp-lib/api-service-client/public';

import { Loading } from '../../components/Loading';
import { type Organization, OrgSubscriptionUtils } from '../../types';
import { err2s } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import { type Action, ActionSheet } from '../ActionSheet';
import { ErrorMessage } from '../Game/GameCenter';
import { useTriggerSendAnalyticsDigestModal } from '../GlobalPromotion/Modals/AnalyticsDigest';
import { DeleteIcon } from '../icons/DeleteIcon';
import { useProducts } from '../Product/useProducts';
import { useOrganizations } from './hooks';
import { useTriggerCreateOrganizationModal } from './Modals/CreateOrganizationModal';
import { useTriggerDeleteOrganizationModal } from './Modals/DeleteOrganizationModal';
import { OrganizationSelect } from './OrganizationSelect';
import { formatOrgSizeLabel } from './OrganizationSize';

export function SubscriptionProductFilter(props: {
  value: string | null | undefined;
  onChange: (product: DtoProduct | null) => void;
}): JSX.Element {
  const { value, onChange } = props;

  const styles = useMemo(
    () =>
      buildReactSelectStyles<DtoProduct>({
        override: {
          control: {
            height: '40px',
          },
          groupHeading: {
            fontSize: '0.75rem',
            fontWeight: 600,
            color: '#4A4A4A',
            textTransform: 'uppercase',
            letterSpacing: '0.05rem',
          },
        },
      }),
    []
  );

  // note(falcon): not using an async select component because we need to look up the selected
  // value. not sure if we can do this with react select.
  const { data, isLoading, error } = useProducts();
  const options = useMemo(() => {
    if (!data) return [];
    const options = [];
    options.push({
      label: 'Published',
      options: data.published,
    });
    options.push({
      label: 'Archived',
      options: data.archived,
    });
    return options;
  }, [data]);

  const selected = useMemo(() => {
    if (!value) return null;
    if (!data) return null;
    return [...data.published, ...data.archived].find((p) => p.id === value);
  }, [value, data]);

  if (isLoading) {
    return (
      <div className='w-full flex items-center justify-center'>
        <Loading text='' />
      </div>
    );
  }

  if (error) {
    return (
      <div className='w-full flex items-center justify-center'>
        <p className='text-red-001'>{err2s(error)}</p>
      </div>
    );
  }

  return (
    <Select<DtoProduct, false>
      placeholder='Filter by product'
      styles={styles}
      classNamePrefix='select-box-v2'
      className='w-full h-full'
      value={selected}
      options={options}
      onChange={onChange}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isSearchable={false}
      isClearable
    />
  );
}

function OrganizationActionSheet(props: {
  organization: Organization;
  onDelete: () => void;
}): JSX.Element {
  const triggerSendAnalyticsDigest = useTriggerSendAnalyticsDigestModal();

  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'delete',
      icon: <DeleteIcon />,
      text: 'Delete',
      className: 'text-red-002',
      onClick: () => {
        props.onDelete();
      },
    },
    {
      kind: 'button',
      key: 'send-analytics-digest',
      icon: <div>💬</div>,
      text: 'Send Analytics Digest',
      onClick: () => {
        triggerSendAnalyticsDigest({
          organization: props.organization,
        });
      },
    },
  ];

  return <ActionSheet actions={actions} />;
}

type OrganizationFilters = {
  overSeatLimit?: boolean;
  productId?: string;
  orgId?: string;
  missingLogo?: boolean;
};

function useOrganizationFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = useMemo(() => {
    const filters: OrganizationFilters = {};
    if (searchParams.has('overSeatLimit')) {
      filters.overSeatLimit = searchParams.get('overSeatLimit') === 'true';
    }
    if (searchParams.has('productId')) {
      filters.productId = searchParams.get('productId') ?? undefined;
    }
    if (searchParams.has('orgId')) {
      filters.orgId = searchParams.get('orgId') ?? undefined;
    }
    if (searchParams.has('missingLogo')) {
      filters.missingLogo = searchParams.get('missingLogo') === 'true';
    }
    return filters;
  }, [searchParams]);

  const setFilters = useCallback(
    (filters: OrganizationFilters) => {
      setSearchParams((prev) => {
        for (const filterName in filters) {
          const value = uncheckedIndexAccess_UNSAFE(filters)[filterName];
          if (value === undefined) {
            prev.delete(filterName);
          } else {
            prev.set(filterName, String(value));
          }
        }
        return prev;
      });
    },
    [setSearchParams]
  );

  const clearFilters = useCallback(() => {
    setSearchParams((prev) => {
      prev.delete('overSeatLimit');
      prev.delete('productId');
      prev.delete('orgId');
      prev.delete('missingLogo');
      return prev;
    });
  }, [setSearchParams]);

  return {
    filters,
    setFilters,
    clearFilters,
  };
}

function OrganizationFilterControl(): JSX.Element {
  const { filters, setFilters } = useOrganizationFilters();

  return (
    <div>
      <div className='flex items-center gap-4'>
        <div className='w-50'>
          <OrganizationSelect
            className='w-full'
            orgId={filters.orgId ?? null}
            onChange={(org) =>
              setFilters({
                ...filters,
                orgId: org?.id,
              })
            }
            isClearable
            noFakeOrg
          />
        </div>
        <div className='w-50'>
          <SubscriptionProductFilter
            value={filters.productId}
            onChange={(p) =>
              setFilters({
                ...filters,
                productId: p?.id,
              })
            }
          />
        </div>
        <div className='flex flex-row items-center'>
          <input
            type='checkbox'
            className='checkbox-dark'
            checked={filters.overSeatLimit ?? false}
            onChange={(e) =>
              setFilters({
                ...filters,
                overSeatLimit: e.target.checked ? true : undefined,
              })
            }
          />
          <p className='ml-2 text-sms'>Over Seat Limit</p>
        </div>
        <div className='flex flex-row items-center'>
          <input
            type='checkbox'
            className='checkbox-dark'
            checked={filters.missingLogo ?? false}
            onChange={(e) =>
              setFilters({
                ...filters,
                missingLogo: e.target.checked ? true : undefined,
              })
            }
          />
          <p className='ml-2 text-sms'>Missing Logo</p>
        </div>
      </div>
    </div>
  );
}

export const OrganizationList = (): JSX.Element => {
  const navigate = useNavigate();
  const triggerCreateOrganizationModal = useTriggerCreateOrganizationModal();
  const triggerDeleteOrganizationModal = useTriggerDeleteOrganizationModal();
  const { data, isLoading, error, mutate } = useOrganizations();

  const { filters } = useOrganizationFilters();

  const organizations = useMemo(
    () =>
      data?.filter((org) => {
        if (filters.orgId) {
          if (org.id !== filters.orgId) return false;
        }
        if (filters.overSeatLimit) {
          if (org.maxSize === null || org.organizersCount <= org.maxSize)
            return false;
        }
        if (filters.productId) {
          if (org.subscription.productId !== filters.productId) return false;
        }
        if (filters.missingLogo) {
          if (org.logo) return false;
        }
        return true;
      }),
    [data, filters]
  );

  if (isLoading) return <Loading />;
  if (error)
    return (
      <ErrorMessage text='Something went wrong' handleRetry={() => mutate()} />
    );

  const handleCreate = () => {
    triggerCreateOrganizationModal({
      onConfirm: () => mutate(),
    });
  };

  const handleDelete = (organization: Organization) => {
    triggerDeleteOrganizationModal({
      organization,
      onDelete: () => mutate(),
    });
  };

  return (
    <div className='text-white w-full h-full p-12'>
      <header className='flex justify-between items-center'>
        <h1 className='font-bold text-4xl'>Organizations</h1>
        <button
          type='button'
          onClick={handleCreate}
          className='w-50 h-12.5 bg-secondary border border-secondary rounded-xl text-secondary text-base font-medium tracking-wider'
        >
          New Organization
        </button>
      </header>

      <main className='w-full mt-7'>
        <OrganizationFilterControl />

        <table className='mt-2 w-full'>
          <thead>
            <tr className='text-left h-12 text-base text-bold'>
              <th className='pl-2.5'>Name</th>
              <th>Members</th>
              <th>Org Size</th>
              <th>Created</th>
              <th>Slack/Teams</th>
              <th>Subscription Product</th>
              <th>Subscription Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {organizations?.map((organization) => (
              <tr
                onClick={() =>
                  navigate(`/admin/organizations/${organization.id}`)
                }
                key={organization.id}
                className='w-full h-10 text-sms cursor-pointer hover:bg-lp-gray-002'
              >
                <td className='pl-2.5'>
                  <p className='max-w-40 truncate'>{organization.name}</p>
                </td>
                <td>{organization.organizersCount}</td>
                <td>{formatOrgSizeLabel(organization.maxSize)}</td>
                <td>
                  {format(new Date(organization.createdAt), 'MM/dd/yyyy')}
                </td>
                <td>
                  {!organization.connection
                    ? 'Not Connected'
                    : `${capitalize(organization.connection.type)}${
                        organization.connection.status === 'inactive'
                          ? ' (Disconnected)'
                          : ''
                      }`}
                </td>
                <td>
                  {OrgSubscriptionUtils.GetSubscriptionPlanName(organization)}
                </td>
                <td>
                  {capitalize(organization.subscription.status ?? 'active')}
                </td>
                <td>
                  <div className='flex flex-row-reverse items-center pr-1.5'>
                    <OrganizationActionSheet
                      organization={organization}
                      onDelete={() => handleDelete(organization)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {organizations?.length === 0 && (
          <div className='w-full mt-30 flex items-center justify-center text-secondary'>
            No Organizations.
          </div>
        )}
      </main>
    </div>
  );
};
